<template>
  <div>
    <section class="white">
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <h2
              :class="[$vuetify.breakpoint.mdAndUp ? 'display-3' : 'headline']"
              class="mb-4"
            >
              Δίπλωμα Ταξί
            </h2>

            <p class="subtitle-1">
              Φορτηγά από 3501 kg έως το μέγιστο επιτρεπόμενο βάρος του κάθε φορτηγού και ρυμουλκούμενου ή επικαθήμενου οριζόμενου από την εκάστοτε νομοθεσία. Ο υποψήφιος πρέπει να είναι κάτοχος Γ κατηγορίας. Ισχύει για 5 έτη από την ημερομηνία έκδοσης ή την τελευταία ανανέωση.
            </p>
            <br>
            <p class="display-1">
              ΠΡΟΫΠΟΘΕΣΕΙΣ
            </p><p class="subtitle-1">
              <ul>
                <li>Ελληνικό ΑΦΜ</li>
                <li>Δίπλωμα Αυτοκινήτου (Β κατηγορίας)</li>
                <li>Ελληνική Ταυτότητα ή άδεια παραμονής ή εργασίας (σε ισχύ για τουλάχιστον 185 ημέρες)</li>
                <li>Τίτλο σπουδών υποχρεωτικής εκπαίδευσης</li>
                <li>Πιστοποιητικό Ποινικού Μητρώου γενικής χρήσης, (το οποίο αναζητείται αυτεπάγγελτα από την Υπηρεσία)</li>
                <li>2 φωτογραφίες τύπου διαβατηρίου (4cm x 6cm)</li>
                <li>Ιατρικά Πιστοποιητικά από Παθολόγο, Οφθαλμίατρο & Ψυχίατρο</li>
                <li>τα εξής παράβολα (από το e-Παράβολο) :</li>
                <ul>
                  <li>50€ (Έκδοση Ειδικής Άδειας Οδήγησης ΕΔΧ αυτοκινήτου κωδ. 2986)</li>
                  <li>20€ (Συμμετοχή στην ειδική γραπτή θεωρητική εξέταση κωδ. 8051)</li>
                </ul>
                <li>να συμπληρωυθεί η Αίτηση Χορήγησης Άδειας ΕΔΧ ΤΑΞΙ (Μ-ΤΑΟ20)</li>
                <li>να συμπληρωυθεί η Αίτηση Συμμετοχής στην Ειδική Θεωρητική Εξέταση για την ΕΔΧ Αυτοκινητού (Μ-ΤΑΟ19)</li>
                <br>
              </ul>
            </p>
            <v-btn
              color="success"
              class="px-5"
              large
              :to="`/contact-me`"
            >
              Επικοινωνηστε τωρα
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="5"
            offset-md="1"
          >
            <v-card>
              <v-img src="/static/taxi.png" />
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section class="grey lighten-3">
      <v-container>
        <h2
          :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline']"
          class="mb-4"
        >
          Απαιτούμενα δικαιολογητικά για κάτοχο Β κατηγορίας από 21 ετών
        </h2>
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-card-title v-text="highlight" />
            <v-text>
              <ul>
                <li>Ταυτότητα ή ελληνικό διαβατήριο σε ισχύ. (Για όσους δεν έχουν ελληνική υπηκοότητα χρειάζεται άδεια διαμονής που να εμφανίζονται 186 ημέρες συνεχής διαμονής στην Ελλάδα και διαβατήριο</li>
                <li>ΑΦΜ (αν δεν υπάρχει πρέπει να εκδοθεί από την εφορία)</li>
                <li>Φωτοτυπία διπλώματος</li>
                <li>Τέσσερις (4) έγχρωμες φωτογραφίες διαβατηρίου</li>
                <li>Παράβολο 20€ (κωδ: 8151) από την Εφορία ή ηλεκτρονικά απο το <a href="https://www1.gsis.gr/sgsisapps/eparavolo/public/welcome.htm?fbclid=IwAR3fE4tXJ-I1NlFsMfud2NLlWjn13VrFOmBm7AICQlCda0kdDPQt2XN73ww">e-paravolo</a> για το δικαίωμα γραπτής εξέτασης</li>
                <li>Απολυτήριο Δημοτικού για γεννημένους έως 31/3/1969 και στη συνέχεια Γυμνασίου ή Λυείου.(Για όσους δεν έχουν ελληνική υπηκοότητα χρειάζεται  Πιστοποιητικό Ελληνομάθειας απο το Υ.ΠΑΙΔΕΙΑΣ)</li>
                <li>Υπεύθυνη Δήλωση (την προμηθεύεστε απο την σχολή ή την κατεβάζετε απο το site)</li>
              </ul>
            </v-text>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section class="white pb-0">
      <v-container>
        <h2
          :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline']"
          class="mb-4"
        >
          Αφού επιτύχουν τις εξετάσεις
        </h2>
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-card-title v-text="highlight" />
            <v-text>
              <ul>
                <li>Παράβολο 50€ (κωδ:2986)</li>
                <li>Έκδοση των πιστοποιητικών υγείας από συμβεβλημένους γιατρούς του Υ.Μ.Ε. (Παθολόγο και Οφθαλμίατρο) μαζί με την απόδειξη των γιατρών</li>
                <li>Βιβλιάριο Ασθενείας (αν δεν υπάρχει χρειάζονται οι εξής εξετάσεις: Γενική αίματος, ουρία, ζάκχαρο, ακτινογραφία θώρακος, καρδιογράφημα)</li>
                <li>Ποινικό μητρώο (το οποίο ζητάτε υπηρεσιακά από το ΥΜΕ)</li>
              </ul>
            </v-text>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section class="white pt-0">
      <v-container>
        <div class="text-center font-weight-bold">
          <v-text>
            <br>
            Υποχρεωτικά μαθήματα ΔΕΝ απαιτούνται<br>
            Τα παράβολα τα κόβει η σχολή χωρίς επιβάρυνση!<br>
            Τα παράβολα εκδίδονται απο την εφορία ή ηλεκτρονικά απο το <a href="https://www1.gsis.gr/sgsisapps/eparavolo/public/welcome.htm?fbclid=IwAR3fE4tXJ-I1NlFsMfud2NLlWjn13VrFOmBm7AICQlCda0kdDPQt2XN73ww">e-paravolo</a> και τα αναλαμβάνει και η σχολή να τα εκδώσει χωρίς επιβάρυνση!<br>
            Αν ο υποψήφιος αποτύχει στις γραπτές εξετάσεις ξανακόβει παράβολο από την εφορία 20€ (κωδ: 8151), έτσι ώστε να μπορέσει να πάρει μέρος και άλλη φορά στις εξετάσεις.
          </v-text>
        </div>
      </v-container>
    </section>

    <section class="grey lighten-3">
      <v-container>
        <v-row>
          <v-col
            v-for="([name, path], i) in pdfs"
            :key="i"
            cols="6"
            md="6"
          >
            <div class="text-center">
              <div
                class="headline font-weight-bold mb-3"
                v-text="name"
              />
              <v-btn
                color="success"
                class="px-5"
                large
                @click="readFile(path)"
              >
                ΛΗΨΗ
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
  export default {
    data: () => ({
      experiences: [
        ['mdi-steering', '8 ώρες', 'Πρακτικα μαθηματα'],
        ['mdi-book-open-variant', '0 ώρες', 'θεωρητικα μαθηματα']
      ],
      pdfs: [
        ['Ειδική Θεωρητική Εξέταση', 'static/ειδική-θεωρητική-εξέταση-Μ-ΤΑ019.pdf'],
        ['Χορήγηση Ειδικής Άδειας Οδήγησης', 'static/ειδική-άδεια-οδήγησης-Μ-ΤΑΟ20.pdf'],
        ['Ανανέωση Ειδικής Άδειας Οδήγησης', 'static/ανανέωση-ειδικής-άδειας-ΜΤΑΟ-21.pdf'],
        ['e-Παράβολο', 'https://www1.gsis.gr/sgsisapps/eparavolo/public/welcome.htm']
      ]
    }),

    methods: {
      readFile (path) {
        window.open(path, '_blank')
      }
    }
  }
</script>
